<template>
  <div class="email-editor">
    <div v-if="emailTemplate && emailTemplate.design" class="container">
      <div id="bar">
        <h4>Edit {{ emailTemplate.name }} email template</h4>

        <v-btn color="primary" small @click="saveDesign">Save Design</v-btn>
        <v-btn color="primary" small @click="exportHtml"> Export HTML </v-btn>
      </div>

      <email-editor
        :appearance="appearance"
        :min-height="minHeight"
        :project-id="projectId"
        :locale="locale"
        :tools="tools"
        :options="options"
        ref="emailEditor"
        v-on:load="editorLoaded"
        v-on:ready="editorReady"
      />
    </div>
    <div v-else class="no-records"><i>Loading...</i></div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { EmailEditor } from "vue-email-editor";

import vendorEmailTemplate from "@/store/modules/vendorEmailTemplate";

const { mapActions: vETemplateActions } = createNamespacedHelpers(
  "EMAIL_TEMPLATE_EDITOR"
);

export default {
  name: "EmailTemplateEditor",
  components: {
    "email-editor": EmailEditor,
  },
  props: {
    templateId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    minHeight: "1000px",
    locale: "en",
    projectId: 88572, // replace with your project id
    tools: {
      image: {
        enabled: true,
      },
    },
    options: {
      mergeTags: {
        clientNname: {
          name: "Client Name",
          value: "{{client_name}}",
          // sample: "John",
        },
        businessName: {
          name: "Business Name",
          value: "{{business_name}}",
        },
        businessPhone: {
          name: "Business Phone",
          value: "{{business_phone}}",
        },
        businessEmail: {
          name: "Business Email",
          value: "{{business_Email}}",
        },
      },
    },
    appearance: {
      theme: "dark",
      panels: {
        tools: {
          dock: "right",
        },
      },
    },
  }),
  computed: {
    emailTemplate() {
      return this.$store.getters[
        "EMAIL_TEMPLATE_EDITOR/getVendorEmailTemplate"
      ](this.templateId);
    },
    role() {
      return this.$store.getters.role;
    },
  },
  watch: {
    role() {
      if (this.role) {
        this.options.mergeTags.businessName.value = this.role.business.name;
        this.options.mergeTags.businessEmail.value = this.role.user.email;
        if (this.role.user.phoneNumner)
          this.options.mergeTags.businessPhone.value =
            this.role.user.phoneNumner;
      }
    },
  },
  created() {
    this.fetchVendorEmailTemplate(`?vendorEmailTemplateId=${this.templateId}`);
  },
  methods: {
    ...vETemplateActions([
      "fetchVendorEmailTemplate",
      "updateVendorEmailTemplate",
    ]),
    // called when the editor is created
    editorLoaded() {
      // Pass your template JSON here
      this.$refs.emailEditor.editor.loadDesign(this.emailTemplate.design);
    },
    // called when the editor has finished loading
    editorReady() {
      console.log("editorReady");
    },
    saveDesign() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.updateVendorEmailTemplate({
          id: this.templateId,
          vendorEmailTemplate: {
            design: data.design,
            // businessId: this.role.business._id,
            // name: this.emailTemplate.name,
            html: data.html,
          },
        }).then((t) => (this.template = t));
      });
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.updateVendorEmailTemplate({
          id: this.templateId,
          vendorEmailTemplate: {
            html: data.html,
          },
        });
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EMAIL_TEMPLATE_EDITOR")) {
      this.$store.registerModule("EMAIL_TEMPLATE_EDITOR", vendorEmailTemplate);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EMAIL_TEMPLATE_EDITOR");
  },
};
</script>

<style>
iframe {
  height: 100vh !important;
}

.display-none,
.blockbuilder-branding {
  display: none !important;
}
</style>
